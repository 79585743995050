import { render, staticRenderFns } from "./SearchWizardHomepageBanner.vue?vue&type=template&id=36f203f4&scoped=true"
import script from "./SearchWizardHomepageBanner.vue?vue&type=script&lang=ts"
export * from "./SearchWizardHomepageBanner.vue?vue&type=script&lang=ts"
import style0 from "./SearchWizardHomepageBanner.vue?vue&type=style&index=0&id=36f203f4&prod&lang=css"
import style1 from "./SearchWizardHomepageBanner.vue?vue&type=style&index=1&id=36f203f4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f203f4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,SearchWizardHomepageBannerCategories: require('/app/apps/search-wizard/homepage-banner/components/SearchWizardHomepageBannerCategories.vue').default,PuSearchWizardLink: require('/app/apps/pu-links/link-search-wizard/PuSearchWizardLink.vue').default,SearchBarDesktop: require('/app/apps/search-wizard/campsites-search/components/SearchBarDesktop.vue').default,CampsitesSearchWizardMain: require('/app/apps/search-wizard/campsites-search/components/CampsitesSearchWizardMain.vue').default,SearchWizardHomepageBannerOptions: require('/app/apps/search-wizard/homepage-banner/components/SearchWizardHomepageBannerOptions.vue').default,HomepageBackground: require('/app/components/homepage/HomepageBackground.vue').default,SocialProof: require('/app/components/homepage/social-proof/SocialProof.vue').default})
