import { interpret } from 'xstate'
import { rootContainer } from '~/container'
import sentryCapture from '~/utility/sentryCapture'
import FetchLocationCampsiteSuggestions from './campsites-search/location/infrastructure/FetchLocationCampsiteSuggestions'
import FetchLocationGeoSuggestions from './campsites-search/location/infrastructure/FetchLocationGeoSuggestions'
import FetchLocationHierarchySuggestions from './campsites-search/location/infrastructure/FetchLocationHierarchySuggestions'
import { returnSuggestionsMachine } from './campsites-search/location/machine/suggestionsMachine'

export function getSuggestionsMachine(langCode: string) {
  return interpret(
    returnSuggestionsMachine().withConfig({
      services: {
        getHierarchySuggestions: ({ input }) =>
          new FetchLocationHierarchySuggestions().fetch(input, langCode),
        getCampsiteSuggestions: ({ input }) =>
          new FetchLocationCampsiteSuggestions().fetch(input, langCode),
        getCustomGeoSuggestions: ({ input }) =>
          new FetchLocationGeoSuggestions().fetch(input, langCode),
      },
      actions: {
        logError: (_, event: any) => {
          event.data.name = 'SuggestionsMachine ' + event.type + ' error'
          sentryCapture(event.data, rootContainer)
        },
      },
    }),
  ).start()
}
