
import { SearchParty } from '../../../search/schemas'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class SearchWizardHomepageBannerOptionsParty extends Vue {
  @Prop({ required: true, type: [Object, String] })
    party: SearchParty | ''

  get childrenCount() {
    return this.party ? this.party.childAges.length : 0
  }
}
