
import { SearchParty } from '../../../search/schemas'
import { ArriveDepartIso } from '../../../search/types'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class SearchWizardHomepageBannerOptions extends Vue {
  @Prop({ required: true, type: [Object, String] })
    party: SearchParty | ''

  @Prop({ required: true, type: [Object, String] })
    dates: ArriveDepartIso | ''

  get areAdultsInUrl() {
    return !!this.$route.query.adults
  }
}
