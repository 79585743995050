
import { usePinia, useSearchWizardStore } from '#imports'
import { Component, Vue, Watch } from '~/utility/pu-class-decorator'
import { routeToFilters } from '../../../pu-links/infrastructure/routeToFilters'
import type { SearchParty } from '../../../search/schemas'
import type { ArriveDepartIso } from '../../../search/types'

@Component({
  setup() {
    const searchWizardStore = useSearchWizardStore(usePinia())
    searchWizardStore.openOnFocus = true
    return { searchWizardStore }
  },
})
export default class SearchWizardHomepageBanner extends Vue {
  searchWizardStore: ReturnType<typeof useSearchWizardStore>

  @Watch('searchWizardStore.isSearchWizardOpen')
  searchIsOpen() {
    if (
      process.client &&
      this.searchWizardStore.isSearchWizardOpen &&
      !this.$isDesktop
    ) {
      document
        .getElementById('__nuxt')
        ?.classList.add('homepage-search-open', 'active-tab-search')
    }
  }

  get categoryIds(): string[] {
    return this.filters.categoryIds || []
  }

  get party(): SearchParty | '' {
    return this.filters.party || ''
  }

  get dates(): ArriveDepartIso | '' {
    return this.filters.dates || ''
  }

  get filters() {
    return routeToFilters(this.$route)
  }
}
