
import { Component, Vue } from '~/utility/pu-class-decorator'
@Component({
  components: {
    'portal-target': () => {
      if (process.client) {
        return import(
          /* webpackChunkName: "vue-portal", webpackMode: "lazy" */ 'portal-vue'
        ).then(({ PortalTarget }) => PortalTarget)
      } else {
        return
      }
    },
  },
})
export default class WizardContainer extends Vue {}
